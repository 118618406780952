<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height class="black">
        <v-carousel height="100%">
          <v-carousel-item class="mb-10">
            <v-container class="fixedParallax" fluid>
              <v-parallax src="./assets/birches.jpg"></v-parallax>
            </v-container>
            <v-row
                justify="center"
            >
              <h1 class="font-weight-thin pt-10 white--text">
                Б А Р М А Г Л О Т
              </h1>
            </v-row>
            <v-row class="pt-5 text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                Варкалось. Хливкие шорьки<br>
                Пырялись по наве,<br>
                И хрюкотали зелюки,<br>
                Как мюмзики в мове.
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center">
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                О бойся Бармаглота, сын!<br>
                Он так свирлеп и дик,<br>
                А в глуще рымит исполин -<br>
                Злопастный Брандашмыг.
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center">
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                Hо взял он меч, и взял он щит,<br>
                Высоких полон дум.<br>
                В глущобу путь его лежит<br>
                Под дерево Тумтум.
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center">
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                Он стал под дерево и ждет,<br>
                И вдруг граахнул гром -<br>
                Летит ужасный Бармаглот<br>
                И пылкает огнем!
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center" >
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                Раз-два, раз-два! Горит трава,<br>
                Взы-взы - стрижает меч,<br>
                Ува! Ува! И голова<br>
                Барабардает с плеч.
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center" >
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                О светозарный мальчик мой!<br>
                Ты победил в бою!<br>
                О храброславленный герой,<br>
                Хвалу тебе пою!
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center" >
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="pb-5 white--text font-weight-thin">
                Варкалось. Хливкие шорьки<br>
                Пырялись по наве,<br>
                И хрюкотали зелюки,<br>
                Как мюмзики в мове.
              </h3>
            </v-row>
          </v-carousel-item>
          <v-carousel-item class="mb-10">
            <v-container class="fixedParallax" fluid>
              <v-parallax src="./assets/red.jpg"></v-parallax>
            </v-container>
            <v-row
                justify="center"
            >
              <h1 class="font-weight-thin pt-10 white--text">
                З М Е Е Г Р Ы Ч
              </h1>
            </v-row>
            <v-row class="pt-5 text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                Червело. Ужные мрави<br>
                Кузали на снову.<br>
                За нисом прали курави,<br>
                Склюняя пелаву.
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center">
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                А длиннохрастый Змеегрыч<br>
                Уже рептит на зель,<br>
                И слышен плюстоустый злыч<br>
                За триныжды мезель.
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center">
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                И хребосклон темел. И бум<br>
                Гулел, как барабал.<br>
                Под дерным веревом Тум-Тум<br>
                Храбо Гатырь затал.
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center">
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                Твержал в крепке он чит и щеч,<br>
                И зорк его смолел.<br>
                Он Змеегрычу мог отсвечь<br>
                Триныжды головел.
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center" >
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                Стражись, ужалый Змеегрыч,<br>
                Мерзей своей дрожбой!<br>
                Но встречь заграчил воплый крыч<br>
                И рыклый крылый вой.
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center" >
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                Звекнул - раз-раз! - плоострый щеч,<br>
                И грыкнул длиннохраст.<br>
                Смерщела мразкая калечь.<br>
                Ура! Свержит злобаст!
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center" >
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="pb-5 white--text font-weight-thin">
                Червело. Ужные мрави<br>
                Кузали на снову.<br>
                За нисом прали курави,<br>
                Склюняя пелаву.
              </h3>
            </v-row>
          </v-carousel-item>
          <v-carousel-item class="mb-10">
            <v-container class="fixedParallax" fluid>
              <v-parallax src="./assets/gold.jpg"></v-parallax>
            </v-container>
            <v-row
                justify="center"
            >
              <h1 class="font-weight-thin pt-10 white--text">
                М О Р Д О Л А К
              </h1>
            </v-row>
            <v-row class="pt-5 text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                Ложбилась смуть у возлесов.<br>
                Смерчки клонялись в зем.<br>
                Жельдей мурчащих горлосов<br>
                Был свышен хряпот всем.<br>
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center">
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                Отрочье! Смрачен Мордолак,<br>
                Угрюмен и ловещ,<br>
                А в древнях створожит совраг -<br>
                Воротливый Заплещ!<br>
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center">
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                Но, хватно задымая сеч,<br>
                Храбрелец в Златы влез<br>
                И поскачествовал навстречь<br>
                Там - тамошних древес.<br>
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center">
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                Затайно скоротясь за щит<br>
                И в ждаль уперив гляд,<br>
                Он зрел, как Мордолак трыщит<br>
                И огнемечет смрад!<br>
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center" >
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                Раз - два - лся крежет! Но гудар<br>
                Взы - взы - бил звен сеча,<br>
                И гряхнул головянный шмар,<br>
                Упадно дохоча!
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center" >
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="white--text font-weight-thin">
                О дерзновейший мой храбрёл!<br>
                Твой цветел горделик!<br>
                Поют возлес и мшарный мздол,<br>
                Как сподвиг твой велик!
              </h3>
            </v-row>
            <v-row class="pt-5 pb-5" justify="center" >
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
              <v-icon x-small>
                mdi-sword-cross
              </v-icon>
            </v-row>
            <v-row class="text-center" justify="center">
              <h3 class="pb-5 white--text font-weight-thin">
                Ложбилась смуть у возлесов.<br>
                Смерчки клонялись в зем.<br>
                Жельдей мурчащих горлосов<br>
                Был свышен хряпот всем.
              </h3>
            </v-row>
          </v-carousel-item>
        </v-carousel>
        <v-speed-dial fixed bottom right direction="top" class="mb-5 mr-5">
          <template v-slot:activator>
            <v-btn
                fab
                fixed
                bottom
                right
                color="secondary"
            >
              <v-icon>
                $vuetify.icons.jabberwocky
              </v-icon>
            </v-btn>
          </template>
          <v-btn
              fab
              class="mr-5 mb-10"
              color="secondary"
              v-on:click="ogJb = !ogJb"
          >
            <v-icon>mdi-book-open-page-variant</v-icon>
          </v-btn>
          <v-btn
              fab
              class="mr-5 mb-5"
              color="secondary"
              v-on:click="audio = !audio"
          >
            <v-icon>mdi-music</v-icon>
          </v-btn>
          <v-btn
              fab
              class="mr-5 mb-5"
              color="secondary"
              v-on:click="openLink()"
          >
            <v-icon>mdi-bookmark-check</v-icon>
          </v-btn>
        </v-speed-dial>

        <v-dialog
            v-model="audio"
            max-width="400"
        >
          <vuetify-audio :file="file" color="secondary" autoPlay flat :ended="audioFinish" class="pt-7"></vuetify-audio>
        </v-dialog>
        <v-dialog
            v-model="ogJb"
            scrollable
            max-width="400"
        >
          <v-card>
            <v-card-text>
              <v-row
                  justify="center"
              >
                <h2 class="font-weight-thin pt-10 black--text">
                  J A B B E R W O C K Y
                </h2>
              </v-row>
              <v-row class="pt-5 text-center" justify="center">
                <h3 class="black--text font-weight-thin">
                  Twas brillig, and the slithy toves<br>
                  Did gyre and gimble in the wabe:<br>
                  All mimsy were the borogoves,<br>
                  And the mome raths outgrabe
                </h3>
              </v-row>
              <v-row class="pt-5 pb-5" justify="center">
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
              </v-row>
              <v-row class="text-center" justify="center">
                <h3 class="black--text font-weight-thin">
                  Beware the Jabberwock, my son!<br>
                  The jaws that bite, the claws that catch!<br>
                  Beware the Jubjub bird, and shun<br>
                  The frumious Bandersnatch!
                </h3>
              </v-row>
              <v-row class="pt-5 pb-5" justify="center">
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
              </v-row>
              <v-row class="text-center" justify="center">
                <h3 class="black--text font-weight-thin">
                  He took his vorpal sword in hand:<br>
                  Long time the manxome foe he sought -<br>
                  So rested he by the Tumtum tree<br>
                  And stood awhile in thought.
                </h3>
              </v-row>
              <v-row class="pt-5 pb-5" justify="center">
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
              </v-row>
              <v-row class="text-center" justify="center">
                <h3 class="black--text font-weight-thin">
                  And, as in uffish thought he stood,<br>
                  The Jabberwock, with eyes of flame,<br>
                  Came wiffling through the tulgey wood,<br>
                  And burbled as it came
                </h3>
              </v-row>
              <v-row class="pt-5 pb-5" justify="center" >
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
              </v-row>
              <v-row class="text-center" justify="center">
                <h3 class="black--text font-weight-thin">
                  One, two! One, two! And through, and through<br>
                  The vorpal blade went snicker-snack!<br>
                  He left it dead, and with its head<br>
                  He went galumphing back.
                </h3>
              </v-row>
              <v-row class="pt-5 pb-5" justify="center" >
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
              </v-row>
              <v-row class="text-center" justify="center">
                <h3 class="black--text font-weight-thin">
                  And hast thou slain the Jabberwock?<br>
                  Come to my arms, my beamish boy!<br>
                  A frabjous day! Callooh! Callay!<br>
                  He chortled in his joy.
                </h3>
              </v-row>
              <v-row class="pt-5 pb-5" justify="center" >
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
                <v-icon x-small>
                  mdi-sword-cross
                </v-icon>
              </v-row>
              <v-row class="text-center" justify="center">
                <h3 class="pb-5 black--text font-weight-thin">
                  Twas brillig, and the slithy toves<br>
                  Did gyre and gimble in the wabe:<br>
                  All mimsy were the borogoves,<br>
                  And the mome raths outgrabe.
                </h3>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {
    VuetifyAudio: () => import('vuetify-audio'),
  },
  data() {
    return {
      ogJb: null,
      audio: null,
      file: require("./assets/jabberwocky.mp3")
    }
  },
  methods: {
    audioFinish() {
      this.audio = null;
    },
    openLink() {
      window.open('http://www.wonderland-alice.ru/public/kurij2/JABBERWOCKY/');
    }
  }
}
</script>

<style>
.fixedParallax {
  position: absolute;
  background-color: #000000;
  z-index: -1;
  top: 30%;
  padding: unset;
  margin: unset;
}
</style>